<template>
  <v-app>
    <v-main>
      <EppydesignHeader class= "fixed" />
      <EppydesignGallery/>
      <EppydesignAbout/>
      <EppydesignShowreel/>
      <EppydesignContact/>
      <div class="footer"></div>
    </v-main>
  </v-app>
</template>

<script>
import EppydesignHeader from './components/EppydesignHeader.vue'
import EppydesignGallery from './components/EppydesignGallery.vue'
import EppydesignAbout from './components/EppydesignAbout.vue'
import EppydesignShowreel from './components/EppydesignShowreel.vue'
import EppydesignContact from './components/EppydesignContact.vue'

export default {
  name: 'App',

  components: {
    EppydesignHeader,
    EppydesignGallery,
    EppydesignAbout,
    EppydesignShowreel,
    EppydesignContact,
  },

  data: () => ({
    //
  }),
}
</script>
<style>
body {
  font-family: 'Open Sans', sans-serif;
  color: #130c0e !important;
}
v-main {
  margin-top: 100px;
}
html {
  scroll-behavior: smooth;
}
.footer {
  padding-top: 50px;
}
</style>