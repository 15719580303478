<template>
  <h1 id="showreel" class="showreel showreel-header">SHOWREEL</h1>
  <div class="text-content">
  <p class="showreel showreelpara">Over the past few years, I've expanded my creative expertise from static design to motion design.<br> Please take a moment to watch the following showreel, showcasing the animation and video editing projects I worked on during my time at ISG Ltd.</p>
  </div>
  <div class="showreel video">
      <div class="video-container">
          <iframe src="https://www.youtube.com/embed/tzGnPUfF020" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
  </div>
</template>

<script></script>

<style scoped>
.showreel {
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  background-color: #f3f3f4;
  margin: 0 50px;
  padding: 20px 100px;
  color: #130c0e;
  letter-spacing: 0.05em;
  text-align: center;
}

h1 {
  margin-top: 20px;
  font-size: 60px;
}

h1#showreel {
  scroll-margin-top: 200px;
}

.video-container {
  position: relative;
  width: 100%; 
  padding-top: 56.25%; 
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
}

@media (max-width: 768px) {
  .showreel {
    background-color: transparent;
  }
  .video {
    display: flex;
    justify-content: center; 
  }
  h1 {
    font-size: 30px;
    text-align: center;
  }
  .showreel {
    padding: 0px;
    margin: 0px 30px;
  }
  .text-content {
    padding-bottom: 20px;
    text-align: center;
    margin: 0 40px;
  }
  .showreel-header {
    font-size: 30px;
    padding: 10px 0;
  }
  p {
    font-size: 12px;
  }
  .video-container iframe {
    height: 100%;
  }
}
</style>
