<template>
  <v-dialog v-model="visible" max-width="1200" max-height="800" centered>
    <template v-slot:default="{ props }">
      <div v-bind="props" class="dialog-overlay">
        <div class="dialog-content">
          <v-img
            :src="imageSrc"
            class="image"
            width="1200"
            height="800"
            contain
          ></v-img>
          <v-btn class="close-button" @click="closeDialog" color="#a7a4dd" icon="mdi-close"></v-btn>          
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    closeDialog() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.dialog-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120%;
  height: 120%;
}
.dialog-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
</style>
