<template>
  <div class="header">
    <a href="#gallery">
      <img :src="headerImage" alt="headerlogo" class="headerlogo">
    </a>
    <div class="social-icons">
      <a href="mailto:rachel@eppydesign.com">
        <img :src="email" alt="email" class="email">
    </a>
      <a href="https://www.linkedin.com/in/rachel-eppy-096a9750/" target="_blank" rel="noopener noreferrer">
        <img  :src="linkedin" alt="linkedin" class="linkedin">
      </a>
      
    </div>
    <nav>
      <ul class="navbar">
        <li><a href="#gallery" @click="setActive($event)">PORTFOLIO</a></li>
        <li><a href="#about" @click="setActive($event)">ABOUT</a></li>
        <li><a href="#showreel" @click="setActive($event)">SHOW REEL</a></li>
        <li><a href="#contact" @click="setActive($event)">CONTACT</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'EppydesignHeader',
  data() {
    return {
      headerImage: require('../assets/header/eppydesign.png'), 
      email: require('../assets/header/email.png'), 
      linkedin: require('../assets/header/linkedin.png'),
    };
  },
  methods: {
    setActive(event) {
      const links = document.querySelectorAll('.navbar li a');
      links.forEach(link => link.classList.remove('active'));
      
      event.target.classList.add('active');
    },
  },
}
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  position: fixed;  
  top: 0;
  width: 100%; 
  background-color: white;  
  z-index: 1000;
  padding: 10px 0;
}

.social-icons {
  position: absolute;
  top: 10px; 
  right: 50px;
  display: flex;
  margin-top: 20px;
}

nav {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 20px 20px;
}

.navbar {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  margin: 0 25px;
}

.navbar li a {
  text-decoration: none;
  color: black;
  transition: color 0.4s ease; 
}

.navbar li a:hover, .navbar li a.active {
  color: #a7a4dd; 
 }

.headerlogo {
  width: 500px; 
  height: auto;
  animation: bounce 1s ease;
}

.email {
  width: 40px; 
  height: 30px;
  margin-right: 10px; 
}

.linkedin {
  width: 30px; 
  height: 30px;
}

.email:hover, .linkedin:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .headerlogo {
    width: 300px; 
    height: auto;
  }
  .social-icons {
    display: none;
  }
  .navbar {
    display: none;
  }
  .header {
    padding: 0px;
    padding-top: 10px;
    height: 90px;
  }
}
</style>
