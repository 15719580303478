<template>
    <h1 id="contact" class="contact">SAY HELLO!</h1>
    <div class="contact">
        <div class="cards bottom">
            <a href="/cv-rachel-2024.pdf" download>
                <img :src="cvCard" alt="cv-card" class="card"/>
            </a>
            <a href="mailto:rachel@eppydesign.com">
                <img :src="emailCard" alt="email-card" class="card"/>
            </a>
            <a href="https://www.linkedin.com/in/rachel-eppy-096a9750/" target="_blank" rel="noopener noreferrer">
                <img :src="linkedInCard" alt="linkedin-card" class="card"/>
            </a>
        </div>
    </div>
    <h1 class="mobileContact">SAY HELLO!</h1>
    <div class="mobileContact">
        <div class="cards">
            <a href="/cv-rachel-2024.pdf" download>
                <img :src="cvCardMob" alt="cv-card" class="card"/>
            </a>
            <a href="mailto:rachel@eppydesign.com">
                <img :src="emailCardMob" alt="email-card" class="card"/>
            </a>
            <a href="https://www.linkedin.com/in/rachel-eppy-096a9750/" target="_blank" rel="noopener noreferrer">
                <img :src="linkedInCardMob" alt="linkedin-card" class="card"/>
            </a>
        </div>
    </div>
    <div class="bottom"></div>
</template>

<script>
export default {
    name: 'EppydesignContact',
    data() {
        return {
            cvCard: require('../assets/contact/cv-card.jpg'),
            emailCard: require('../assets/contact/email-card.jpg'),
            linkedInCard: require('../assets/contact/linkedin-card.jpg'), 
            cvCardMob: require('../assets/contact/cv-card-mob.jpg'),
            emailCardMob: require('../assets/contact/email-card-mob.jpg'),
            linkedInCardMob: require('../assets/contact/linkedin-card-mob.jpg'), 
        };
    },
};
</script>

<style scoped>
.contact, .mobileContact {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f4;
    margin: 0 50px;
    padding: 20px 100px;
    color: #130c0e;
    letter-spacing: 0.05em;
}
.mobileContact {
    display: none;
    background-color: transparent;
}
h1 {
    margin-top: 20px;
    font-size: 60px;
    text-align: center;
}

h1#contact {
    scroll-margin-top: 200px;
}

.cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.card {
    flex: 1;
    width: 100%;
    max-width: 300px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.6);
}
.bottom {
    padding: 25px;
}
@media (max-width: 768px) {
    .contact {
        display: none;
    }

    .mobileContact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .cards {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .card {
        max-width: 310px;
        margin: 15px 0;
        padding: 0;
    }
    h1 {
        margin-top: 20px;
        font-size: 30px;
        text-align: center;
    }
    .card:hover {
        transform: scale(1);
        box-shadow: none;
    }
    .bottom {
    padding-bottom: 0px;
}
}
</style>
