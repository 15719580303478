<template>
  <h1 id="about" class="about about-header">ABOUT</h1>
  <div class="about">
    <img :src="aboutMeImage" alt="About Me" class="aboutme" />
    <div class="text-content">
      <p>
        Hi! I’m Rachel, a professional graphic designer and illustrator based in Bedford, with over 13 years of diverse design experience. My creative journey actually began at the age of 4, when I first picked up a pencil. Like many children, I was captivated by all things Disney and after watching ‘The Lion King’, drawing became my passion. Once I perfected my sketches using traditional media, my focus shifted to digital art, where I began learning Adobe software during my teenage years. This passion led me to pursue and earn a BA (Hons) in Illustration from The Cambridge School of Art at Anglia Ruskin University in 2011.
        <br /><br />
        After graduating, I founded ‘Eppyart’, my freelance illustration and design service. Over the years, I’ve gained extensive experience creating professional artwork and designs across a variety of industries, from pharmaceutical to fashion. In my most recent role as Executive Graphic Designer for a global construction firm, I expanded into motion design, animation and video editing, adding new dimensions to my creative toolkit.
        <br /><br />
        I’m now on the lookout for my next creative adventure! If you’re searching for a friendly, enthusiastic and imaginative designer to bring your ideas to life, I’d love to hear from you.
      </p>
      <a href="mailto:rachel@eppydesign.com">
        <v-btn class="create-button" color="#a7a4dd">Let’s create something amazing together!</v-btn>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EppydesignAbout',
  data() {
    return {
      aboutMeImage: require('../assets/about/about-me.png'),
    };
  },
};
</script>

<style scoped>
.about-header {
  margin-top: 100px;
  font-size: 60px;
  text-align: center;
}

.about {
  display: flex; 
  justify-content: center; 
  align-items: flex-start;
  background-color: #f3f3f4;
  margin: 0 50px;
  padding: 20px 100px;
  color: #130c0e;
  letter-spacing: 0.05em;
}

.aboutme {
  width: 40%; 
  max-width: 400px; 
  height: auto;
  margin-right: 20px;
  margin-bottom: 20px;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%; 
}

.create-button {
  margin: 20px 0px;
  padding: 8px 16px;
  font-size: 14px;  
  width: 100%; 
}

h1#about {
  scroll-margin-top: 200px;
}

a {
  text-decoration: none;
}

@media (max-width: 768px) {
  .about-header {
    font-size: 30px;
    padding: 40px 0 20px 0;
  }
  
  .about {
    background-color: transparent;
    padding: 0;
    margin: 0;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
  }

  .aboutme {
    width: 80%; 
    max-width: 200px; 
    margin: 0;
    padding: 0;
  }

  .text-content {
    align-items: center;
    text-align: center;
    width: 80%; 
    margin: 0 15px;
  }

  p {
    font-size: 12px;
    background-color: #f3f3f4;
    margin: 0 15px; 
    padding: 10px; 
  }

  .create-button {
    margin: 20px 0px 0px 0px;
    padding: 12px;
    font-size: 11px;
    display: block;
  }
}
</style>


  