<template>
  <div id="gallery" class="gallery">
    <v-row>
      <v-col v-for="n in imageAmount" :key="n" class="d-flex child-flex" cols="3">
        <v-img
          :src="require(`../assets/gallery/gallery-${n + change}.jpg`)"
          aspect-ratio="1"
          class="bg-grey-lighten-2 images"
          cover
          @click="openPopup(require(`../assets/gallery/gallery-${n + change}.jpg`))"
          style="cursor: pointer;"
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="grey-lighten-5" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="paginator">
      <v-btn class="ma-2" color="#a7a4dd" @click="left" icon="mdi-chevron-left"></v-btn>
      <v-btn class="ma-2" color="#a7a4dd" @click="right" icon="mdi-chevron-right"></v-btn>
    </v-row>
    <Popup ref="Popup" :imageSrc="selectedImage" />
  </div>
  <div class="mobileGallery">
    <v-container class="mobileGallery">
      <v-carousel hide-delimiters class="custom-carousel" height="280" width="280">
        <template v-slot:prev="{ props }">
          <v-btn class="ma-2 custom-small" color="#a7a4dd" icon="mdi-chevron-left" @click="props.onClick"></v-btn>
</template>
<template v-slot:next="{ props }">
  <v-btn class="ma-2 custom-small" color="#a7a4dd" icon="mdi-chevron-right" @click="props.onClick"></v-btn>
</template>
        <v-carousel-item
          v-for="n in totalImages"
          :key="n"
          :src="require(`../assets/mobilegallery/mob-gallery-${n}.jpg`)"
          class="carousel-image"
        ></v-carousel-item>
      </v-carousel>
    </v-container>
  </div>
</template>

<script>
import Popup from './dialogs/EppydesignGalleryPopup.vue';

export default {
  name: 'EppydesignHeader',
  components: {
    Popup,
  },
  data() {
    return {
      // UPDATE TOTAL IMAGES ON THIS NUMBER
      totalImages: 16,
      imageAmount: 8,
      scrolling: 8,
      change: 0,
      selectedImage: '',
    };
  },
  methods: {
    left() {
      if (this.change !== 0) this.change -= this.scrolling;
    },
    right() {
      if (this.change !== 4){
        this.change += this.scrolling;
      }
    },
    openPopup(image) {
      this.selectedImage = image;
      this.$refs.Popup.visible = true;
    },
  },
};
</script>

<style scoped>
.gallery {
  background-color: #f3f3f4;
  margin: 220px 50px 0px 50px;
  padding: 20px 100px;
  color: #130c0e;
}
.mobileGallery {
  display: none;
}
div#gallery {
  scroll-margin-top: 220px;
}
.paginator {
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}
.images:hover {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.6);
}
@media (max-width: 768px) {
  .gallery {
    display: none;
  }
  .mobileGallery {
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color: transparent;
    margin: 30px 50px 0px 50px;
  }
  .custom-carousel {
    height: 200px; 
    width: 275px; 
  }
  .carousel-image {
    object-fit: cover; 
    height: 100%; 
    width: 100%; 
  }
  .custom-small {
  width: 30px; 
  height: 30px;
}
}
</style>
